import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useApi() {
  const router = useRouter();
  const getDatas = async () => {
    return await ApiService.get("building_permit_waits");

  };

  const getData = async (id) => {
    return await ApiService.get("building_permit_waits/" + id);

  };
  const FinishAticket = async (data) => {
    await ApiService.put("building_permit_waits/" + data.id, data);
    await router.push({
      name: "apps.building_permits.show",
      params: { id: data.id },
    });
  };

  

  return {
    getDatas,
    getData,
    FinishAticket
  };
}
